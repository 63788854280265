import React from 'react'
import { Flex, Skeleton, Table, Button } from '@buffer-mono/popcorn'

import { graphql } from '~publish/graphql'
import { useQuery } from '@apollo/client'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { formatCurrency } from './PlanContent'

import settingsStyles from '../Settings.module.css'
import { useGetPortalSession , PortalOptions } from '../hooks/useGetPortalSession'
import { getSettingsUrl } from '../util/getSettingsUrl'

const InvoicesQuery = graphql(/* GraphQL */ `
  query Invoices($organizationId: String!, $first: Int, $after: String) {
    invoicesByOrganization(
      organizationId: $organizationId
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          amount
          status
          downloadUrl
          date
          currency
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`)

// from 2025-03-31T17:27:53.000Z to July 17, 2024 style
function formatDate(date: string): string {
  const dateObj = new Date(date)
  return dateObj.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export const Invoices = (): JSX.Element => {
  const organizationId = useOrganizationId()
  const { data, loading } = useQuery(InvoicesQuery, {
    variables: {
      organizationId: organizationId ?? '',
      first: 3,
    },
    skip: !organizationId,
  })

  const { getCustomerPortalSession, loading: portalSessionLoading } =
    useGetPortalSession({
      portalOption: PortalOptions.MAIN_PAGE,
    })

  const invoices = data?.invoicesByOrganization.edges
    .map((edge) => edge?.node)
    .filter((invoice) => invoice !== undefined)

  return (
    <>
      <Skeleton show={loading}>
        <Flex gap="xs" direction="row" align="center">
          Invoices &amp; Receipts
        </Flex>
      </Skeleton>
      <Skeleton show={loading}>
        <Table fullWidth>
          <Table.Header>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {invoices?.map((invoice) => (
              <Table.Row key={invoice.id}>
                <Table.Cell>{formatDate(invoice.date)}</Table.Cell>
                <Table.Cell>${formatCurrency(invoice.amount)}</Table.Cell>
                <Table.Cell align="right">
                  <Button
                    size="small"
                    variant="tertiary"
                    as="a"
                    href={invoice.downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'var(--color-text-neutral)',
                      fontWeight: 'medium',
                      display: 'inline-flex',
                    }}
                  >
                    View
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Flex justify="center" className={settingsStyles.fullWidth}>
          <Button
            size="medium"
            variant="tertiary"
            onClick={(): void => {
              getCustomerPortalSession(getSettingsUrl())
            }}
            disabled={portalSessionLoading}
          >
            View All
          </Button>
        </Flex>
      </Skeleton>
      {/* Spacer */}
      <div style={{ marginTop: 'var(--space-2xl)' }}></div>
    </>
  )
}

export default Invoices
