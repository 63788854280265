import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import clsx from 'clsx'

import {
  BufferIcon,
  Flex,
  Heading,
  HelpIcon,
  IconButton,
  MenuIcon,
  NewBadge,
  Sidebar,
  StatusDot,
  UnstyledButton,
  useBreakpoint,
  useMediaQuery,
} from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import GlobalAction from '../NavBar/components/GlobalAction/GlobalAction'

import MainNavSideBar from './components/Sidebar'
import {
  getPortForProduct,
  getPublishUrl,
  getUrlEnvModifier,
} from '../../common/utils/urls'
import { useCrossAppNavigate } from '../../common/hooks/useCrossAppNavigate'
import BufferLogoWithWords from '../NavBar/components/BufferLogoWithWords'
import { useUser } from '../../common/context/User'
import { ProductNames, Products } from '../../common/constants/products'
import {
  getActiveProductFromUrl,
  getActiveProductName,
} from '../../exports/Navigator/utils/getActiveProductFromUrl'
import UnreadEngageCommentsCounter from '../NavBar/components/UnreadEngageCommentsCounter/UnreadEngageCommentsCounter'
import HelpMenu from '../NavBar/components/HelpMenu/HelpMenu'
import { useHasSeenLauncherInHelpMenu } from '../Launcher/hooks'
import { DISMISS_OBJECT } from '../../common/graphql/account'

import StreakWidget from '../NavBar/components/StreakWidget/StreakWidget'
import AccountMenu from '../NavBar/components/AccountMenu/AccountMenu'
import AccountMenuAvatar from '../NavBar/components/AccountMenu/components/AccountMenuAvatar'
import UpgradeCTA from '../../common/components/UpgradeCTA'
import { WhatsNew } from './components/WhatsNew'

import { useShouldShowNewlyCommentsDot } from '../NavBar/hooks/useShouldShowNewlyCommentsDot'
import { useProductHistory } from '../../exports/Navigator/utils/useProductHistory'
import UpgradePathsTracking from '../../tracking/UpgradePaths'
import { shouldTrackNavCTA } from '../NavBar/components/NavBarProducts/utils/shouldTrackNavCTA'
import { isFreePlan } from '../../common/hooks/utils/segmentTraitGetters'
import { userCanStartFreeTrial } from '../../common/utils/user'

import helpMenuStyles from '../NavBar/components/HelpMenu/HelpMenu.module.css'
import accountMenuStyles from '../NavBar/components/AccountMenu/AccountMenu.module.css'
import styles from './Navigation.module.css'

const PageHeader = (): JSX.Element => {
  const activeProduct = getActiveProductName()
  return (
    <Flex>
      <Heading style={{ textTransform: 'capitalize' }}>{activeProduct}</Heading>
    </Flex>
  )
}

export default function Navigation(): JSX.Element {
  const [activeProduct, setActiveProduct] = useState<ProductNames | null>(
    getActiveProductFromUrl(),
  )

  const { history } = useProductHistory()
  const user = useUser()
  const currentOrganization = user?.currentOrganization

  const { trackCTAClicked } = UpgradePathsTracking(user)

  const isTightTopNav = useMediaQuery('(max-width: 892px)')
  const breakpoint = useBreakpoint()
  const shouldUseLargeLogo = breakpoint === 'lg' || breakpoint === 'xl'

  const navigateCrossApp = useCrossAppNavigate()
  const environmentModifier = getUrlEnvModifier()

  const [, { called: dismissedNewLabel }] = useMutation(DISMISS_OBJECT)
  const launcherIsANewItemInHelpMenu = useHasSeenLauncherInHelpMenu()

  const showStartPage = currentOrganization?.role === 'admin'

  const showNewBadgeForHelpMenu =
    launcherIsANewItemInHelpMenu && !dismissedNewLabel

  const { isEnabled: isCommentsPageEnabled } = useSplitEnabled('geid-comments')
  const { showDot: shouldShowDot, setShowDot: showDot } =
    useShouldShowNewlyCommentsDot()

  const isImpersonation = user.isImpersonation
  const hasVerifiedEmail = user.hasVerifiedEmail
  const showStatusDot = !!user.id && !hasVerifiedEmail
  const avatar = user.avatar
  const isFreeUser = isFreePlan(user)
  const canStartTrial = userCanStartFreeTrial(user)

  const mainProducts = [
    ProductNames.CREATE,
    ProductNames.PUBLISH,
    ...(isCommentsPageEnabled
      ? [ProductNames.COMMENTS]
      : [ProductNames.ENGAGE]),
    ProductNames.ANALYZE,
    ...(showStartPage ? [ProductNames.START_PAGE] : []),
  ]

  const productList = Products.filter((product) =>
    mainProducts.includes(product.id),
  )

  const handleTrackProductClick = (id: ProductNames): void => {
    if (
      shouldTrackNavCTA({
        isFreeUser,
        activeProductId: activeProduct ?? undefined,
        currentProductId: id,
      })
    ) {
      trackCTAClicked({
        ctaView: 'mainNav',
        ctaLocation: 'mainNav',
        ctaButton: id,
        upgradePathName: `${id}-${canStartTrial ? 'trial' : 'upgrade'}`,
      })
    }
  }

  const handleUpdateActiveProduct = useCallback((): void => {
    setActiveProduct(getActiveProductFromUrl())
  }, [])

  useEffect(() => {
    const unlisten = history?.listen(handleUpdateActiveProduct)
    return () => unlisten?.()
  }, [history, handleUpdateActiveProduct])

  if (breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl') {
    return (
      <nav
        aria-label="Main menu"
        className={clsx(styles.nav, {
          [styles.tight]: breakpoint === 'md',
          [styles.wide]: breakpoint === 'lg' || breakpoint === 'xl',
        })}
      >
        <Flex
          direction="row"
          fullWidth
          align="center"
          className={styles.leftContainer}
        >
          {shouldUseLargeLogo ? (
            <BufferLogoWithWords className={styles.largeLogo} />
          ) : (
            <UnstyledButton
              className={styles.logoButton}
              aria-label="Go to Publish"
              onClick={(): void => navigateCrossApp(getPublishUrl())}
            >
              <BufferIcon />
            </UnstyledButton>
          )}
          <Flex
            direction="row"
            align="start"
            className={styles.productLinksContainer}
          >
            {productList.map((product) => {
              const origin = `https://${
                product.subdomain
              }.${environmentModifier}buffer.com${getPortForProduct(
                product.id,
              )}`
              const url = `${origin}/${product.pathname ?? ''}`
              const isActiveProduct = activeProduct === product.id

              if (
                product.id === ProductNames.PUBLISH ||
                product.id === ProductNames.CREATE
              ) {
                return (
                  <UnstyledButton
                    key={product.id}
                    className={clsx(
                      styles.productLink,
                      isActiveProduct && styles.activeProduct,
                    )}
                    onClick={(): void => navigateCrossApp(url)}
                  >
                    {product.label}
                  </UnstyledButton>
                )
              }

              if (product.id === ProductNames.COMMENTS) {
                return (
                  <UnstyledButton
                    key={product.id}
                    className={clsx(
                      styles.productLink,
                      isActiveProduct && styles.activeProduct,
                    )}
                    onClick={(): void => {
                      showDot(false)
                      handleTrackProductClick(product.id)
                      navigateCrossApp(url)
                    }}
                  >
                    {product.label}
                    {shouldShowDot && (
                      <StatusDot
                        className={styles.newCommentsDot}
                        data-testid="new-comments-dot"
                      />
                    )}
                  </UnstyledButton>
                )
              }

              return (
                <UnstyledButton
                  key={product.id}
                  className={clsx(
                    styles.productLink,
                    isActiveProduct && styles.activeProduct,
                  )}
                  as="a"
                  href={url}
                  onClick={(): void => handleTrackProductClick(product.id)}
                >
                  <Flex align="center">
                    {product.label}
                    {!isTightTopNav && product.isNew && (
                      <NewBadge className={styles.newBadge} />
                    )}

                    {product.id === ProductNames.ENGAGE && (
                      <UnreadEngageCommentsCounter />
                    )}
                  </Flex>
                </UnstyledButton>
              )
            })}
          </Flex>
          <Flex
            direction="row"
            align="center"
            justify="between"
            className={styles.rightItemsContainer}
          >
            {!isTightTopNav && <UpgradeCTA />}
            <GlobalAction />
            <Flex
              align="center"
              gap="xs"
              className={styles.iconButtonsContainer}
            >
              <StreakWidget />
              <HelpMenu>
                <IconButton
                  variant="tertiary"
                  label="Help Menu"
                  size="large"
                  className={helpMenuStyles.triggerButton}
                >
                  <>
                    <HelpIcon />
                    {showNewBadgeForHelpMenu && (
                      <StatusDot
                        data-testid="new-badge"
                        className={helpMenuStyles.statusDot}
                      />
                    )}
                  </>
                </IconButton>
              </HelpMenu>
              <WhatsNew>
                <IconButton variant="tertiary" label="What's New" size="large">
                  <WhatsNew.Icon />
                </IconButton>
              </WhatsNew>
            </Flex>
            <AccountMenu user={user}>
              <IconButton
                variant="tertiary"
                label="User Menu"
                size="large"
                className={clsx(styles.accountMenuTrigger, {
                  [accountMenuStyles.isImpersonation]: isImpersonation,
                })}
              >
                <>
                  <AccountMenuAvatar
                    avatar={avatar}
                    isImpersonation={isImpersonation}
                  />
                  {showStatusDot && (
                    <StatusDot
                      data-testid="email-not-verified-badge"
                      className={accountMenuStyles.avatarBadge}
                    />
                  )}
                </>
              </IconButton>
            </AccountMenu>
          </Flex>
        </Flex>
      </nav>
    )
  }

  // Mobile Navigation
  // Uses Sidebar and a minimal top nav layout
  return (
    <Flex direction="column" fullWidth>
      <Sidebar.Provider mobileFallback={true}>
        <nav aria-label="Main menu" className={styles.nav}>
          <Flex direction="row" fullWidth justify="between" align="center">
            <Sidebar.Trigger>
              <IconButton
                variant="tertiary"
                size="large"
                label="Open main navigation"
                className={styles.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Sidebar.Trigger>
            <PageHeader />
            <GlobalAction />
          </Flex>
        </nav>
        <MainNavSideBar productList={productList} />
      </Sidebar.Provider>
    </Flex>
  )
}
